import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import 'material-design-icons-iconfont/dist/material-design-icons.css'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    dark: false, // Indica si el tema inicial es oscuro o claro
    options: {
      customProperties: true // Habilita el uso de propiedades personalizadas
    },
    themes: {
      light: {
        bgPrimary: '0fa7bf'
      },
      dark: {
        primary: '#000000'
      }
    }
  }
})
