
import Vue from 'vue'
import { mapMutations } from 'vuex'
export default Vue.extend({
  name: 'App',
  data: () => ({
    //
  }),
  methods: {
    ...mapMutations('usuario', ['authData'])
  },
  created () {
    this.authData()
  }
})
