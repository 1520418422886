import state from './state'
import mutations from './mutations'
import actions from './actions'
import getters from './getters'

const usuarioModule = {
  namespaced: true, // Esto permite utilizar el espacio de nombres del módulo
  state,
  mutations,
  actions,
  getters
}

export default usuarioModule
