import { StateUser } from '@/interfaces/state.usuario.interfaces'

const getters = {
  login (state: StateUser) {
    return state.login
  },
  token (state: StateUser) {
    return state.token
  },
  refreshToken (state: StateUser) {
    return state.refreshToken
  }
}

export default getters
