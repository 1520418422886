import { StateUser } from '@/interfaces/state.usuario.interfaces'

const mutations = {
  login (state: StateUser, payload: any) {
    state.login = payload.login
    state.refreshToken = payload.refreshToken
    state.token = payload.token
  },
  authData (state: StateUser) {
    if (state.login) {
      return
    }

    if (localStorage.getItem('token') || localStorage.getItem('login')) {
      state.refreshToken = localStorage.getItem('refreshToken') || ''
      state.token = localStorage.getItem('token') || ''
      state.login = !!localStorage.getItem('login')
    }
  }

}

export default mutations
