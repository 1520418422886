import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('../components/app/Login.vue')
  },
  {
    path: '/',
    redirect: 'login',
    name: 'Layout',
    component: () =>
      import(/* webpackChunkName: "about" */ '@/components/app/Layout.vue'),
    children: [
      {
        path: '',
        name: 'Inicio',
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/biblioteca',
        name: 'Biblioteca',
        meta: {
          requiresAuth: true
        },
        component: () =>
          import(
            /* webpackChunkName: "about" */ '@/components/biblioteca/Biblioteca.vue'
          )
      },
      {
        path: '/categoria/:categoriId',
        name: 'Categorías',
        meta: {
          requiresAuth: true
        },
        component: () =>
          import(
            /* webpackChunkName: "about" */ '@/components/biblioteca/SubCategoria.vue'
          )
      },
      {
        path: '/multimedia/:categoriaId/:subcategoriaId?',
        name: 'Multimedia',
        meta: {
          requiresAuth: true
        },
        component: () =>
          import(
            /* webpackChunkName: "about" */ '@/components/biblioteca/Multimedia.vue'
          )
      },
      {
        path: '/detalle/:categoriaId/:tipomultimediaId/:subcategoriaId?',
        name: 'Detalle',
        meta: {
          requiresAuth: true
        },
        component: () =>
          import(
            /* webpackChunkName: "about" */ '@/components/biblioteca/DetalleMultimedia.vue'
          )
      }
    ]
  },
  {
    path: '/salir',
    name: 'Salir',
    component: () => import('@/components/app/Salir.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// eslint-disable-next-line @typescript-eslint/no-empty-function
router.beforeEach(async (to, from, next) => {
  if (to.meta?.requiresAuth && !localStorage.getItem('login') && !localStorage.getItem('token') && !localStorage.getItem('refreshToken')) {
    next({
      path: '/login',
      replace: true
    })
  } else if (to.name === 'Login' && localStorage.getItem('login') && localStorage.getItem('token') && localStorage.getItem('refreshToken')) {
    next({
      path: '/',
      replace: true
    })
  } else {
    next()
  }
})

export default router
